
export const replaceCommas = (value) => {
    if (typeof value === 'string' && value?.includes(',')) {
        return value?.replaceAll(',', '')
    }
    return value
}
export const normalizeString = (str) => typeof str==='string' ? str?.toLowerCase() : '';
export const normalizeFormattedNumber=(value)=>{
    return Number(replaceCommas(value) || 0)
}