import React, { Component } from 'react';
import success from '../../assets/images/success.svg';
import { Typography, Button } from 'antd';
import Translate from 'react-translate-component';
import {setStep } from '../../reducers/swapfiatcryptoReducer';
import { connect } from 'react-redux';
import NumberFormat from "react-number-format";
class SuccessMsg extends Component {

    onSwapCancel = () => {
        this.props.changeStep("step1")
    }
    render() {
        const { Title, Paragraph, Text } = Typography;
        return (
            
                <div className='custom-declaraton'>
                    <div className="success-pop text-center declaration-content">
                        <img src={success} className="confirm-icon" alt={"success"} />
                        <Translate content="success_msg" component={Title} className="success-title" />
                        <Paragraph className="successsubtext"><Translate content="sucessText1" component={Text} className="successsubtext" /> {" "}
                            <NumberFormat
                                value={this?.props?.setSelectedCoin?.swappingAmount} 
                                displayType="text" thousandSeparator={true} prefix="" /> {" "}{this?.props?.setSelectedCoin?.walletCode}{" "}
                            <Translate content="sucessText4" className="successsubtext" component={Text} />{" "}{parseFloat(this.props?.previewSwapCoin?.amountNativeCurrency).toLocaleString(undefined, { maximumFractionDigits: 20 })}{" "}{this.props?.previewSwapCoin?.currency}.</Paragraph>
                        <Translate content="return_to_swap" component={Button} onClick={() => this.onSwapCancel()} size="large" className="cust-cancel-btn" />
                    </div>
                </div>
            
        );
    }
}
const connectStateToProps = ({ swapfiatcrypto, userConfig }) => {
    return {
        setSelectedToCoin: swapfiatcrypto?.setSelectedToCoin
        , member: userConfig.userProfileInfo,
        setSelectedCoin :swapfiatcrypto?.setSelectedCoin,
        previewSwapCoin: swapfiatcrypto?.previewSwapCoin,
    }
}
const connectDispatchToProps = dispatch => {
    return {
        changeStep: (stepcode) => {
            dispatch(setStep(stepcode))
        },
        dispatch,
    }
}
export default connect(connectStateToProps, connectDispatchToProps)(SuccessMsg);
