import React, { Component } from 'react';
import { Typography, Card, List, Empty, Image, Alert, Radio } from 'antd';
import { connect } from 'react-redux';
import { setStep, setTab, fetchPreviewSwapDetails, setSelectedCoin, setSelectedToCoin, setSelectedDashboardCoin } from '../../reducers/swapfiatcryptoReducer';
import SuisseBtn from '../shared/butons';
import SwapComponent from '../shared/local.crypto.swap/localCryptoSwapperCmp';
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { getFeaturePermissionsByKeyName } from '../shared/permissions/permissionService';
import { getSelectedWallets, getFiatConvertedValue, getCryptoConvertedValue } from './api';
import apicalls from '../../api/apiCalls';
import Translate from 'react-translate-component';
import Loader from '../../Shared/loader';
import Currency from '../shared/number.formate';
import debounce from '../../utils/debounce';
import { normalizeFormattedNumber } from '../../utils/normalizers';
class SwapDetails extends Component {
    myRef = React.createRef();
    swapRef = React.createRef();
    constructor(props) {
        super(props);
        const minValue = this.props?.selectedCoin?.minValue || this.props?.selectedCoin?.swapMinValue || this.props?.setSelectedDashboard?.swapMinValue || "";
        this.state = {
            swapValues: {
                fromAmount: minValue,
                toAmount: undefined,
                onSwap: false,
                isConverting: false
            },
            selectedWallet: null,
            errorMsg: null,
            btnLoading: false,
            supportedWalletDetails: [],
            selectedCurrency: {},
            isLoading: false,
            activeButton: 'min',
            btnDisabled: false,
            selectedCoin: null,
        };
        this.debouncedHandleConversion = debounce(this.handleConversion.bind(this), 300);
    }

    componentDidMount() {
        getFeaturePermissionsByKeyName(`Swap_fiat` || `Swap_crypto`);
        this.props.setTabKey();
        this.getSelectedWalletsDetails(this.props?.userProfileInfo?.id, this.props?.setSelectedDashboard?.coin || this.props?.selectedCoin?.walletCode || this.props?.selectedCoin?.coin, null, true);

    }

    componentDidUpdate(_, prevState) {
        const prevFromAmount=normalizeFormattedNumber(prevState.swapValues.fromAmount);
        const currFromAmount=normalizeFormattedNumber(this.state.swapValues.fromAmount);
        const shouldConvert=prevFromAmount!==currFromAmount
        if (shouldConvert && !this.state.swapValues.onSwap && this.props.setSelectedToCoin) {
            this.onConversion(this.props?.selectedCoin?.walletCode || this.props?.selectedCoin?.coin, this.props?.setSelectedToCoin?.walletCode || this.props?.setSelectedToCoin?.coin, this.state?.swapValues.fromAmount)
        }
    }
    componentWillUnmount() {
        this?.props?.dispatch(setSelectedDashboardCoin(null))
    }
    getSelectedWalletsDetails = async (id, coin, toCoin, onMount = false) => {
        if (!coin) {
            this.setState((prevState) => ({
                ...prevState, errorMsg: null, swapValues: {
                    ...prevState.swapValues,
                    isConverting: false,
                    fromAmount: prevState.swapValues.fromAmount,
                    toAmount: undefined,
                }, btnDisabled: true
            }))
            return;
        }
        this.setState((prevState) => ({ ...prevState, isLoading: true, btnDisabled: false, errorMsg: null }));
        const selectedImpage = this?.props?.setSwapCoins?.filter(item => item?.walletCode === coin)
        if (selectedImpage?.length > 0) {
            this?.props?.dispatch(setSelectedCoin({ impagePath: selectedImpage[0]?.impagePath, amount: selectedImpage[0]?.amount, walletCode: coin, minValue: selectedImpage[0]?.minValue, id: selectedImpage[0]?.id }))
        }
        else if ((!selectedImpage || selectedImpage.length === 0) && this.props.setSelectedDashboard !== null && onMount) {
            this?.props?.dispatch(setSelectedCoin({ impagePath: this?.props?.setSelectedDashboard?.impagePath, amount: this?.props?.setSelectedDashboard?.coinBalance, walletCode: coin, minValue: this.props?.setSelectedDashboard?.swapMinValue, id: this?.props?.setSelectedDashboard?.id }))
        } else if ((!selectedImpage || selectedImpage.length === 0) && this.props.setSelectedDashboard === null && !onMount) {
            this?.props?.dispatch(setSelectedCoin({ impagePath: this?.props?.setSelectedToCoin?.impagePath, amount: this?.props?.setSelectedToCoin?.amount, walletCode: coin, minValue: this.props?.setSelectedToCoin?.minValue, id: this?.props?.setSelectedToCoin?.id }))
        }
        const response = await getSelectedWallets(id, coin, toCoin);
        if (response.ok) {
            this?.props?.dispatch(setSelectedDashboardCoin(null))
            const supportedWalletDetails = response.data || [];
            const toCoinForSelection = supportedWalletDetails.find(item => item.walletCode === toCoin)
            if (supportedWalletDetails.length > 0 && toCoinForSelection) {
                this?.props?.dispatch(setSelectedToCoin(toCoinForSelection))
                this.onConversion(
                    coin,
                    toCoinForSelection?.walletCode,
                    this.state?.swapValues?.fromAmount
                );
            } else if (supportedWalletDetails.length > 0 && !toCoin) {
                this?.props?.dispatch(setSelectedToCoin(supportedWalletDetails[0]))
                this.onConversion(
                    coin,
                    supportedWalletDetails[0]?.walletCode,
                    this.state?.swapValues?.fromAmount
                );
            } else {
                this?.props?.dispatch(setSelectedToCoin(null))
                this.setState((prevState) => ({ ...prevState, swapValues:{
                    ...prevState.swapValues,
                    fromAmount: prevState?.swapValues?.fromAmount,
                    toAmount: undefined,
                } }));
            }
            this.setState((prevState) => ({ ...prevState, swapValues:{...prevState.swapValues,onSwap:false},supportedWalletDetails, isLoading: false, errorMsg: null }));

        } else {
            this.setState({
                errorMsg: apicalls.isErrorDispaly(response),
                isLoading: false,
                supportedWalletDetails: [],
                swapValues: {
                    ...this.state.swapValues,
                    onSwap: false,
                    toAmount: undefined
                }
            });
        }
    }
    getSelectedCurrency = (item) => {
        this.props.dispatch(setSelectedToCoin(item));
        this.onConversion(this.props?.selectedCoin?.walletCode || this.props?.selectedCoin?.coin, item?.walletCode, this.state?.swapValues.fromAmount)
    }

    onValueChange = (value) => {
        this.setState((prevState) => ({
            ...prevState,
            swapValues: {
                fromAmount: value,
                isConverting: false
            },
            errorMsg: null
        }));
    }

    handleConversion = async (fromCurrency, toCurrency, amount) => {
        const originalAmount = amount
        if (amount === "." || amount === "" || amount === 0) {
            amount = 0;
        }
        if (fromCurrency === toCurrency) {
            return;
        }
        let response;
        if (this?.props?.isTab) {
            response = await getCryptoConvertedValue(fromCurrency, toCurrency, amount);
        } else {
            response = await getFiatConvertedValue(fromCurrency, toCurrency, amount);
        }

        if (response.ok) {
            const isMinValue = originalAmount == this?.props?.selectedCoin?.minValue && 'min'
            const isMaxValue = (originalAmount == (this.props?.selectedCoin?.amount || this?.props?.selectedCoin?.coinBalance)) && 'all'
            const activeButton = isMinValue || isMaxValue || ''
            this.setState((prevState) => ({ ...prevState, swapValues: { ...prevState.swapValues, toAmount: response.data || 0, isConverting: false }, activeButton, errorMsg: null }))
        } else {
            this.setState((prevState) => ({
                ...prevState,
                swapValues: {
                    ...prevState.swapValues,
                    isConverting: false
                },
                errorMsg: apicalls.isErrorDispaly(response)
            }));
        }
    }
    onConversion = (fromCurrency, toCurrency, amount) => {
        this.setState((prevState) => ({
            ...prevState,
            errorMsg: null,
            swapValues: {
                ...prevState.swapValues,
                isConverting: true,
                fromAmount: amount,
                toAmount: undefined,
            }, btnDisabled: false
        }));
        this.debouncedHandleConversion(fromCurrency, toCurrency, amount)
    };

    handlePreview = async () => {
        if (this.state.btnDisabled || !this.props.setSelectedToCoin) {
            return;
        }
        const { swapValues } = this.state;
        let _object = this.props?.selectedCoin;
        this.props?.dispatch(setSelectedCoin({
            ..._object,
            currency: this?.props?.setSelectedToCoin?.walletCode,
            swappingAmount: swapValues?.fromAmount,
            convertedValue: swapValues?.toAmount
        }));
        if (swapValues.fromAmount == null || swapValues.fromAmount === "" || swapValues.fromAmount === "0") {
            this.myRef?.current?.scrollIntoView(0, 0);
            this.setState((prevState)=>({...prevState, errorMsg: "Amount must be greater than zero.", btnLoading: false }));
        } else if (swapValues.fromAmount === '.') {
            this.setState((prevState)=>({...prevState,errorMsg: "Please enter amount to swap.", btnLoading: false }));
        }
        else if (swapValues.fromAmount < this?.props?.selectedCoin?.minValue) {
            this.myRef?.current?.scrollIntoView(0, 0);
            this.setState((prevState)=>({...prevState, errorMsg: `You have entered an amount below the minimum swap. The minimum amount is ${this?.props?.selectedCoin?.minValue} ${this.props.selectedCoin?.walletCode || this.props?.setSelectedDashboard?.coin}.`, btnLoading: false }));
        } else if (swapValues.fromAmount > this?.props?.selectedCoin?.amount || this?.props?.selectedCoin?.amount === undefined) {
            this.myRef?.current?.scrollIntoView(0, 0);
            this.setState((prevState)=>({ ...prevState,errorMsg: `Insufficient funds.`, btnLoading: false }));
        } else {
            this.setState({ btnLoading: true, errorMsg: null });
            const response = await this.props.fetchPreviewSwapDetails(
                this.props.selectedCoin?.walletCode || this.props?.selectedCoin?.coin,
                this.props?.setSelectedToCoin?.walletCode || this.props?.setSelectedToCoin?.coin,
                swapValues.fromAmount, this?.props?.isTab
            );
            if (response.ok) {
                this.setState({ btnLoading: false, errorMsg: null, btnDisabled: false });
                this.props.changeStep("step3");
                return;
            } else {
                this.setState((prevState)=>({...prevState, errorMsg: apicalls.isErrorDispaly(response), btnLoading: false }));
            }
        }
        if (this.state.errorMsg) {
            this.myRef?.current?.scrollIntoView(0, 0);
        }
    };

    clickMinamnt = (type) => {
        if(!this?.props?.setSelectedToCoin){
            return;
        }
        if (type === "min") {
            const minValue = (this?.props?.selectedCoin?.minValue || this?.props?.selectedCoin?.minValue === 0) ? this?.props?.selectedCoin?.minValue : this?.props?.selectedCoin?.coinBalance
            this.setState((prevState) => ({
                ...prevState,
                swapValues: {
                    fromAmount: minValue || 0,
                    toAmount: undefined,
                }
            }));
            return
        }
        if (type === "all") {
            const availableBalance = (this.props?.selectedCoin?.coinBalance || this.props?.selectedCoin?.coinBalance === 0) ? this.props?.selectedCoin?.coinBalance : this.props?.selectedCoin?.amount
            this.setState((prevState) => ({
                ...prevState,
                swapValues: {
                    fromAmount: availableBalance || 0,
                    toAmount: undefined,
                }
            }))
        }

    };

    render() {
        const { Paragraph } = Typography;
        const { fromAmount, toAmount, isSwaped, isConverting } = this.state.swapValues;
        const currencySymbols = {
            USD: '$',
            EUR: '€',
            GBP: '£',
            CHF: '₣',
            SGD: 'S$',

        };

        return (
            <div id="divScroll" ref={this.myRef}>
                {this.state.errorMsg !== null && (
                    <Alert
                        closable={false}
                        type="error"
                        description={this.state.errorMsg}
                        onClose={() => this.setState({ errorMsg: null })}
                        showIcon
                    />
                )}
                <div className="selectcrypto-container">
                    {this.state.isLoading && <Loader />}
                    {!this.state.isLoading &&
                        <Card className="crypto-card select" bordered={false}>
                            <div>
                                <SwapComponent
                                    localAmt={fromAmount}
                                    cryptoAmt={toAmount}
                                    localCurrency={this?.props?.selectedCoin?.walletCode || this?.props?.selectedCoin?.coin}
                                    cryptoCurrency={this?.props?.setSelectedToCoin?.walletCode || this?.props?.setSelectedToCoin?.coin}
                                    onChange={(value) => this.onValueChange(value)}
                                    customerId={this.props.userProfileInfo?.id}
                                    screenName='swap'
                                    isSwaped={isSwaped}
                                    onCurrencySwap={() => {
                                        if(this.state.swapValues?.isConverting || !this.props.setSelectedToCoin){
                                            return;
                                        }
                                        this.setState((prevState) => {
                                            const { fromAmount, toAmount } = prevState.swapValues;
                                            return { ...prevState, swapValues: { fromAmount: toAmount, toAmount: fromAmount, onSwap: true } }
                                        },()=>{
                                            this.getSelectedWalletsDetails(
                                                this.props.userProfileInfo?.id,
                                                this.props.setSelectedToCoin?.walletCode || this.props.setSelectedToCoin?.coin,
                                                this.props.selectedCoin?.walletCode || this.props.selectedCoin?.coin
                                            );
                                        })
                                    }}
                                    isConvertionLoad={isConverting}
                                    isCrypto={this?.props?.isTab}
                                />


                            </div>
                            <div className="display-items moblie-order">
                                <div className="text-center mr-16 radio-grp">
                                    <Radio.Group
                                        value={this.state.activeButton}
                                        buttonStyle="solid"
                                        className="round-pills sell-radiobtn-style text-left send-minmax"
                                        onChange={({ target: { value } }) => { this.clickMinamnt(value) }}
                                        disabled={isConverting || this.state.btnLoading || this.state?.btnDisabled || !this.props.setSelectedToCoin}
                                    >
                                        <Translate value="min" content="min" component={Radio.Button} />
                                        <Translate value="all" content="all" component={Radio.Button} />
                                    </Radio.Group>

                                </div>

                                <div className='crypto-details crypto-bal'><div className='sellcrypto-style'>Balance:</div> <Currency
                                    defaultValue={this?.props?.selectedCoin?.amount || this?.props?.selectedCoin?.coinBalance || 0}
                                    prefix={""} type={"text"}
                                    suffixText={this.props.selectedCoin?.walletCode || this.props.selectedCoin?.coin}
                                    className="marginL sellbal-style" />
                                </div>
                            </div>

                            <div className="select-currency">
                                <List
                                    itemLayout="horizontal"
                                    dataSource={this.state.supportedWalletDetails}
                                    className="crypto-list auto-scroll wallet-list selection-currency-list conlist-c-pointer"
                                    locale={{
                                        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
                                    }}
                                    renderItem={item => (
                                        <List.Item
                                            className={(this.props?.setSelectedToCoin?.walletCode || this.props?.setSelectedToCoin?.coin) === item?.walletCode ? 'select' : ''
                                            }
                                            disabled={isConverting || this.state.btnLoading}
                                            onClick={() => this.getSelectedCurrency(item)}
                                        >
                                            <Link className={(this.props?.setSelectedToCoin?.walletCode || this.props?.setSelectedToCoin?.coin) === item?.walletCode ? "c-notallowed" : ""}>
                                                <List.Item.Meta
                                                    avatar={<Image preview={false} src={item.impagePath} />}
                                                    title={<div className="wallet-title">{item.walletCode}</div>}
                                                />
                                                <div className="text-right coin-typo">
                                                    <NumberFormat
                                                        value={item.amount}
                                                        className="drawer-list-font cust-draw-ellipsis"
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={currencySymbols[item.walletCode]}
                                                        renderText={(value, props) => <div {...props}>{value}</div>}
                                                    />
                                                </div>
                                            </Link>
                                        </List.Item>
                                    )}
                                />
                            </div>
                            <div className='buy-paragraph buy-para-wd'>
                                <Translate content="thousandKText" component={Paragraph} className="buy-paragraph " />
                                <Translate content="contact_amount_text" component={Paragraph} className="buy-paragraph" />
                                <div className="buy-usdt-btn">
                                    <SuisseBtn title="PreviewSwap"
                                        disabled={isConverting || this.state.btnLoading || this.state?.errorMsg !== null || this.state?.btnDisabled || !this.props.setSelectedToCoin}
                                        loading={this.state.btnLoading}
                                        onRefresh={() => this.refresh()}
                                        className="pop-btn"
                                        onClick={() => this.handlePreview()}
                                        icon={<span className="icon md load" />} />
                                </div>
                            </div>
                        </Card>}
                </div>
            </div>
        );
    }
}

const connectStateToProps = ({ userConfig, swapfiatcrypto }) => {
    return {
        userProfileInfo: userConfig?.userProfileInfo, selectedCoin: swapfiatcrypto?.setSelectedCoin,
        setSelectedDashboard: swapfiatcrypto?.setSelectedDashboardCoin, setSelectedToCoin: swapfiatcrypto?.setSelectedToCoin,
        setSwapCoins: swapfiatcrypto?.setSwapCoins
    };
}

const connectDispatchToProps = dispatch => {
    return {
        setTabKey: () => dispatch(setTab(1)),
        changeStep: (stepcode) => {
            dispatch(setStep(stepcode));
        },
        fetchPreviewSwapDetails: (fromCurrency, toCurrency, amount, isTab) => dispatch(fetchPreviewSwapDetails(fromCurrency, toCurrency, amount, isTab)),
        dispatch
    }
}

export default connect(connectStateToProps, connectDispatchToProps)(SwapDetails);
