import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Button, Typography, Form, Alert, message, Spin } from 'antd';
import Currency from "../shared/number.formate";
import Translate from 'react-translate-component';
import Loader from '../../Shared/loader';
import { fetchPreviewSwapDetails, setStep } from '../../reducers/swapfiatcryptoReducer';
import { saveFiatSwapDetails, saveCryptoSwapDetails } from './api';
import apicalls from "../../api/apiCalls";
import { fetchDashboardcalls, fetchMarketCoinData } from '../../reducers/dashboardReducer';

const { Paragraph, Title, Text } = Typography;

const PreviewSwap = (props) => {
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [timer, setTimer] = useState(10);
    const [form] = Form.useForm();
    const [errorMsg, setErrorMsg] = useState(null);
    const [btnLoading, setBtnLoading] = useState(false);
    const [effectiveType, setEffectiveType] = useState(false);
    const timerRef = useRef(null);
    const useDivRef = React.useRef(null);

    const fetchDetails = async () => {
        setLoading(true);
        const response = await props.fetchPreviewSwapDetails(
            props.swapfiatcrypto?.walletCode||props?.setSelectedDashboardCoin?.coin,
            props.previewSwapCoin?.currency,
            props.swapfiatcrypto?.swappingAmount,
            props?.isTab
        );
        if (response.ok) {
            setLoading(false);
            setInitialLoading(false);
        } else {
            setErrorMsg(apicalls.isErrorDispaly(response));
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDetails();
    }, []);

    useEffect(() => {
        if (!initialLoading && !loading) {
            timerRef.current = setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer === 1) {
                        fetchDetails();
                        return 10;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }

        return () => {
            if (timerRef.current) clearInterval(timerRef.current);
        };
    }, [initialLoading, loading]);

    const saveSwap = async (values) => {
        if (timerRef.current) clearInterval(timerRef.current); 
        if (!values?.isAccept) {
            useDivRef.current.scrollIntoView();
            setErrorMsg(apicalls.convertLocalLang("agree_termsofservice"));
            return;
        } else {
            setErrorMsg(null);
            setBtnLoading(true);
            let obj = {
                id: "00000000-0000-0000-0000-000000000000",
                customerId: props?.userProfileInfo?.id,
                fromWalletId: props?.swapfiatcrypto?.id || props?.setSelectedDashboardCoin?.id ||props?.setSelectedCoinId,
                fromWalletCode:  props?.swapfiatcrypto?.walletCode||props?.setSelectedDashboardCoin?.coin ,
                fromWalletName: props?.swapfiatcrypto?.walletName,
                fromValue: props.swapfiatcrypto?.swappingAmount,
                toWalletId: props?.setSelectedToCoin?.id,
                toWalletCode: props?.previewSwapCoin?.currency,
                toWalletName: props?.setSelectedToCoin?.walletName,
                toValue: props?.swapfiatcrypto?.convertedValue,
                description: "String",
                comission: props?.previewSwapCoin?.comission,
                exicutedPrice: props?.previewSwapCoin?.oneCoinValue,
                totalAmount: props.previewSwapCoin?.amountNativeCurrency,
                tierDiscount: props?.previewSwapCoin?.tierDiscount,
                sbCredit: props?.previewSwapCoin?.sbCredit,
                totalFee: props?.previewSwapCoin?.totalFee,
                sbFee: props?.previewSwapCoin?.sbFee,
                info: ""
            };

            let response;
            if (props?.isTab) {
                response = await saveCryptoSwapDetails(obj);
            } else {
                response = await saveFiatSwapDetails(obj);
            }

            if (response.ok) {

                setErrorMsg(null);
                setBtnLoading(false);
                props.changeStep("step4");
                props.fetchDashboardData(props?.userProfileInfo?.id);
                props.fetchMarketCoinDataValue();
            } else {
                setBtnLoading(false);
                setErrorMsg(apicalls.isErrorDispaly(response));
            }
        }
    };

    const handleEffectiveFee = () => {
        setEffectiveType(!effectiveType);
    };

    return (
        <>
            {initialLoading ? (
                <Loader />
            ) : (
                <Form form={form} onFinish={saveSwap} autoComplete="off" initialValues={props?.previewSwapCoin}
                    
                    scrollToFirstError
                >
                            <div ref={useDivRef}></div>

                    <div className="cryptosummary-container">
                        {errorMsg !== null && (
                            <Alert
                                type="error"
                                description={errorMsg}
                                onClose={() => setErrorMsg(null)}
                                showIcon
                            />
                        )}
                        <div className="cust-coin-value">
                            <Currency
                                suffixText={props?.previewSwapCoin?.currency}
                                defaultValue={props?.previewSwapCoin?.amountNativeCurrency}
                                prefix={""}
                            />
                        </div>
                        <div className="faitcurrency-style">
							<Currency
								defaultValue={props?.previewSwapCoin?.amount}
								prefix={""}
								type={"text"}
								suffixText={props.swapfiatcrypto?.walletCode}
							/>
						</div>
                        <div className="cust-summary-new">
                            <div className="pay-list">
                                <Translate
                                    className="summary-liststyle"
                                    content="exchange_rate"
                                    component={Text}
                                />
                                <Currency
                                    defaultValue={props?.previewSwapCoin?.oneCoinValue}
                                    className="summarybal"
                                    prefixText={`1 ${props.swapfiatcrypto?.walletCode} = ${props?.previewSwapCoin?.currency}`}
                                    prefix={""}
                                />
                            </div>
                            <div className="pay-list">
                                <Translate
                                    className="summary-liststyle"
                                    content="amount"
                                    component={Text}
                                />
                                 {loading && <Spin size="small" />}{" "}
                                {!loading &&
                                <Currency
                                    defaultValue={props?.previewSwapCoin?.amountNativeCurrency}
                                    className="summarybal"
                                    suffixText={props?.previewSwapCoin?.currency}
                                    prefix={""}
                                    type={"text"}
                                />}
                            </div>

                            <div className="pay-list" style={{ alignItems: 'baseline' }}>
                                <div className="summary-liststyle Effective-Fees"
                                    onClick={() => handleEffectiveFee()}><span>Effective Fees  </span>
                                    <span className={`${effectiveType ? "icon lg up-arrow" : "icon lg down-arrow"}`}>
                                    </span></div>
                                <div className="summarybal">
                                    <Currency
                                        defaultValue={props?.previewSwapCoin?.sbFee}
                                        prefix={""}
                                        className="summarybal"
                                        suffixText={props?.previewSwapCoin?.currency}
                                    />
                                </div>
                            </div>
                            {effectiveType && <> <div className="pay-list" style={{ alignItems: 'baseline' }}>
                                <div className="summary-liststyle">Total Fees</div>
                                <div className="summarybal">
                                    <Currency
                                        defaultValue={props?.previewSwapCoin?.totalFee}
                                        prefix={""}
                                        className="summarybal"
                                        suffixText={props?.previewSwapCoin?.currency}
                                    />
                                </div>
                            </div>
                                {props?.previewSwapCoin?.tierDiscount != 0 && <div className="pay-list" style={{ alignItems: 'baseline' }}>
                                    <div className="summary-liststyle">Tier Discount</div>
                                    <div className="summarybal">
                                        <Currency
                                            defaultValue={props?.previewSwapCoin?.tierDiscount}
                                            prefix={""}
                                            className="summarybal"
                                            suffixText={props.swapfiatcrypto?.walletCode}
                                        />
                                    </div>
                                </div>}
                                {props?.previewSwapCoin?.sbCredit != 0 && <div className="pay-list" style={{ alignItems: 'baseline' }}>
                                    <div className="summary-liststyle">SuisseBase Credit Used</div>
                                    <div className="summarybal">
                                        <Currency
                                            defaultValue={props?.previewSwapCoin?.sbCredit}
                                            prefix={""}
                                            className="summarybal"
                                            suffixText={props.swapfiatcrypto?.walletCode}
                                        />
                                    </div>
                                </div>}
                            </>}
                            <div className="pay-list">
                                <Translate
                                    className="summary-liststyle"
                                    content="estimated_total"
                                    component={Text}
                                />
                               
                                    <Currency
                                        defaultValue={props?.previewSwapCoin?.amount}
                                        className="summarybal"
                                        suffixText={props.swapfiatcrypto?.walletCode}
                                        prefix={""}
                                    />
                            </div>
                        </div>
                        <Translate
                            className="text-style"
                            content="final_Amount"
                            component={Title}
                        />
                        <Form.Item name="isAccept" valuePropName="checked">

                            <div className="d-flex agree-check">
                                <label>
                                    <input
                                        type="checkbox"
                                        id="agree-check"

                                    />
                                    <span htmlFor="agree-check" />
                                </label>
                                <Paragraph className="cust-agreecheck" style={{ flex: 1 }}>
                                    <Translate className="cust-agreecheck" content="agree_sell" component="Paragraph" />{" "}
                                    <a
                                        className="terms-link"
                                        href={process.env.REACT_APP_TERMS_AND_CONDITIONS}
                                        target="_blank">
                                        <Translate content="terms" component="Text" />
                                    </a>{" "}
                                    <Translate content="refund_cancellation" component="Text" />
                                </Paragraph>
                            </div>
                        </Form.Item>
                        <div className="d-flex align-items-center">
                            <Button className={"pop-btn custom-send sell-btc-btn"} style={{ width: 460 }} loading={btnLoading}
                                htmlType="submit">{`Swap (${timer}s)`}</Button>
                        </div>
                    </div>
                </Form>
            )}
        </>
    );
}

const connectStateToProps = ({ swapfiatcrypto, userConfig }) => {
    return {
        swapfiatcrypto: swapfiatcrypto?.setSelectedCoin,
        userProfileInfo: userConfig?.userProfileInfo,
        previewSwapCoin: swapfiatcrypto?.previewSwapCoin,
        setSelectedToCoin: swapfiatcrypto?.setSelectedToCoin,
        setSelectedDashboardCoin:swapfiatcrypto?.setSelectedDashboardCoin,
        setSelectedCoinId:swapfiatcrypto?.setSelectedCoinId
    }
}

const connectDispatchToProps = dispatch => {
    return {
        fetchPreviewSwapDetails: (fromCurrency, toCurrency, amount, isTab) => dispatch(fetchPreviewSwapDetails(fromCurrency, toCurrency, amount, isTab)),
        changeStep: (stepcode) => {
            dispatch(setStep(stepcode))
        },
        fetchDashboardData: (customer_id) => {
            dispatch(fetchDashboardcalls(customer_id))
        },
        fetchMarketCoinDataValue: () => {
            dispatch(fetchMarketCoinData(true))
        },
    }
}

export default connect(connectStateToProps, connectDispatchToProps)(PreviewSwap);
