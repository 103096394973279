import { Input, Spin } from 'antd';
import React, { useMemo } from 'react';
import NumberFormat from 'react-number-format';
const LocalCryptoSwapperCmp = ({ localAmt = 0, cryptoAmt, localCurrency = "USD", cryptoCurrency, onChange, isConvertionLoad, isSwaped, onCurrencySwap, screenName, isCrypto }) => {
  const [fromAmount,fromCurrency,toAmount,toCurrency]=useMemo(()=>{
    if(screenName==='swap'){
      return [localAmt,localCurrency,cryptoAmt,cryptoCurrency]
    }
    const fromAmount=isSwaped ? cryptoAmt : localAmt
    const fromCurrency=!isSwaped ? localCurrency : cryptoCurrency
    const toAmount=isSwaped ? localAmt : cryptoAmt
    const toCurrency=isSwaped ? localCurrency : cryptoCurrency
    return [fromAmount,fromCurrency,toAmount,toCurrency]
  },[screenName,localAmt,cryptoAmt,localCurrency,cryptoCurrency,isSwaped])
  return (
    <div className="p-relative enrty-field-style new-swap-design">
      <div className="enter-val-container swap-com swap-text-sub new-swap-subtext">
        <div className='swap-entryvalue'><NumberFormat
          id="amtInput"
          className="swap-custinputfield swap-text-sub"
          maxLength={9}
          customInput={Input}
          thousandSeparator={true}
          decimalScale={screenName === 'buy' ? 2 : screenName !== 'swap' ? (isSwaped ? 8 : 1) : (isCrypto ? 8 : 2)}
          autoComplete="off"
          placeholder="0"
          bordered={false}
          contenteditable="true"

          value={fromAmount}
          onValueChange={({ value }) => {
            onChange(value);
          }}
          autoFocus
          allowNegative={false}
        /></div>
        <div className='swapcoin-alignemnt crypto-coin-mbspace'><span>{fromCurrency}</span></div>
      </div>
      <div className="swap-text-sub swap-currencytext">
        <div className='swap-entryvalue'><NumberFormat
          value={toAmount}
          displayType={"text"}
          thousandSeparator={true}
          renderText={(value, props) => (
            <div
              {...props}
              className="swap-text-sub"
            >
              {isConvertionLoad ? <Spin size="small" /> : value}
            </div>
          )}
        /></div>
        <div className='swapcoin-alignemnt'><span className='crypto-coin-mtspace'>
          {toCurrency}{" "}
        </span></div>
      </div>
      {screenName === "swap" &&
          <span className="val-updown c-pointer" onClick={onCurrencySwap} disabled={isConvertionLoad}>
            <span className="icon md swaparrow" />
          </span>}
    </div>
  );

}
export default LocalCryptoSwapperCmp;